import { useContext } from "react";
import Moment from "react-moment";
import { JourneyContext } from "../../CONTEXT/JourneyStore";
import ModelAdaptorHelper from "../../HELPERS/ModelAdaptorHelper";
import SimpleBikeList from "./SimpleBikeList";

const SummaryOfCover = ({
  detailsText = "Please ensure all of the details are correct. To amend any information please revert back to the required section using the back button below.",
  fromExternalLink = false,
  validateNextButton = false,
}) => {
  const [gState, setGState] = useContext(JourneyContext);



  return (
    <section className="container container_narrow " id="Quote-Summary">
      <div className="content_section  coverSummary">
        {fromExternalLink && (
          <h2 className="text-left font-27 agg-redFont">{detailsText} </h2>
        )}
        {!fromExternalLink && <p className="blueFont">{detailsText}</p>}
        <h2 className="text-left font-27">
          Summary of <span className="blueFont"> your cover</span>.
        </h2>
        <h2 className="text-left mt-4 font-27">
          Your <span className="blueFont "> details</span>.
        </h2>
        <p className="lowerOpacity lufga-light summaryTitle">Name</p>
        <p className="summaryDetail lufga-regular">
          {gState.forename} {gState.surname}
        </p>
        <br />
        <p className="lowerOpacity lufga-light summaryTitle">Date of birth</p>
        <p className="summaryDetail">
          {gState.dob_d +
            "/" +
            gState.dob_m +
            "/" +
            gState.dob_y}
        </p>
        <br />
        <p className="lowerOpacity lufga-light summaryTitle">
          Address where the bike is usually kept
        </p>
        <address>
          {gState.organisation !== null && (
            <p className="summaryDetail">
              {ModelAdaptorHelper.removeNullText(gState.organisation)}
            </p>
          )}
          <p className="summaryDetail">
            {gState.subHouseName !== null && (
              <>{ModelAdaptorHelper.removeNullText(gState.subHouseName)} </>
            )}
            {gState.houseName !== null && <>{gState.houseName}</>}
            {(gState.houseName == null || gState.houseName?.length < 1) &&
              gState.houseNo !== null && <>{gState.houseNo}</>}
          </p>
          {gState.addressLine1 !== null && (
            <p className="summaryDetail">
              {ModelAdaptorHelper.removeNullText(gState.addressLine1)}{" "}
            </p>
          )}
          {gState.addressLine2 !== null && (
            <p className="summaryDetail">
              {ModelAdaptorHelper.removeNullText(gState.addressLine2)}{" "}
            </p>
          )}
          {gState.addressLine3 !== null && (
            <p className="summaryDetail">
              {ModelAdaptorHelper.removeNullText(gState.addressLine3)}{" "}
            </p>
          )}
          {gState.addressLine4 !== null && (
            <p className="summaryDetail">
              {ModelAdaptorHelper.removeNullText(gState.addressLine4)}{" "}
            </p>
          )}
          <p className="summaryDetail">{gState.postcode}</p>
        </address>
        <p className="lowerOpacity lufga-light summaryTitle ">
          Telephone number
        </p>
        <p className="summaryDetail">{gState.telephoneNo}</p>
        <p className="lowerOpacity lufga-light summaryTitle ">Email address</p>
        <p className="summaryDetail">{gState.email}</p>
        <h2 className="text-left mt-5 font27">
          Your <span className="blueFont"> cover</span>.
        </h2>
        <p className="lowerOpacity lufga-light summaryTitle">
          Cover start date
        </p>
        <p className="summaryDetail">
          <Moment format="DD/MM/YYYY" date={gState.coverStartDate} />
        </p>
        <p className="lowerOpacity lufga-light summaryTitle">Insured cycle/s</p>
        {<SimpleBikeList bikes={gState.bikes} />}
        <p className="lowerOpacity lufga-light summaryTitle">
          Away from home value
        </p>
        <p className="summaryDetail">
          £
          {gState.awayValue == 0
            ? ModelAdaptorHelper.getHomeValue(gState.bikes)
            : gState.awayValue}
        </p>
        <p className="lowerOpacity lufga-light summaryTitle">Bike/s storage</p>
        <p className="summaryDetail">
          {fromExternalLink
            ? "Home (Not an Outbuilding, Communal area or Other)"
            : ModelAdaptorHelper.getStorageLocationFromId(
                gState.storageLocation
              )}
        </p>
        <p className="lowerOpacity lufga-light summaryTitle">Previous claims</p>
        <p className="summaryDetail">{gState.previousClaims ? "Yes" : "No"}</p>
      </div>
    </section>
  );
};

export default SummaryOfCover;
